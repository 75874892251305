html,
body {
  overflow: hidden;
}

#page_content {
  height: 100%;
}

@media (max-width: 660px) {
  html,
  body {
    overflow-y: auto;
    min-height: 800px !important;
  }
}

.loading-content {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 99;
  left: 8%;
}

.logo-loading {
  display: block;

  margin: 0;
  padding: 0;

  color: #fff;
  font-weight: bold;
  font-size: 2em;
  font-family: 'Montserrat', sans-serif;
}

.loading-text {
  display: block;
}
